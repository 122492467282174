import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import { SiteLink } from '../../../components/SiteLink';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Anchor } from '@twilio-paste/anchor';
import { Button } from '@twilio-paste/button';
import { PlusIcon } from '@twilio-paste/icons/esm/PlusIcon';
import { LoadingIcon } from '@twilio-paste/icons/esm/LoadingIcon';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import Changelog from '@twilio-paste/icons/CHANGELOG.md';
export const pageQuery = graphql`
  {
    mdx(fields: {slug: {eq: "/icons/usage-guidelines/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h1>{props.pageContext.frontmatter.title}</h1>
        <Callout mdxType="Callout">
  <CalloutText mdxType="CalloutText">
    Can't find the icon you need? Check <Anchor href="https://streamlineicons.com/" mdxType="Anchor">Streamline</Anchor> and contribute
    it to the library following <SiteLink to="/icons/how-to-add-an-icon" mdxType="SiteLink">our guidelines.</SiteLink>
  </CalloutText>
        </Callout>
        <p>{`Icons are small graphical representation of a program or a function. They can be used to enhance the
appearance of a web interface and break up the monotony of text.`}</p>
        <p>{`Use an icon only when it provides additional clarity or is necessary to draw attention to a UI element.
If an icon is used to indicate hierarchy of a UI element, all elements at the same hierarchy should also be paired with an icon.`}</p>
        <h2>{`Accessibility`}</h2>
        <h3>{`Specify if an icon is decorative`}</h3>
        <p>{`Icons can either be `}<strong parentName="p">{`decorative`}</strong>{` or `}<strong parentName="p">{`informative`}</strong>{`. `}<em parentName="p">{`"Decorative icons don’t add information to the content
of a page. The information provided by the image might already be given using adjacent text, or the image
might be included to make the website more visually attractive."`}</em>{` (`}<Anchor href="https://www.w3.org/WAI/tutorials/images/decorative/" mdxType="Anchor">{`w3.org`}</Anchor>{`)`}</p>
        <p>{`Our icons require the developer define whether an icon is decorative by `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines#making-an-icon-decorative-or-informative"
          }}>{`providing the required property`}</a>{`.`}</p>
        <h3>{`Give informative icons a title`}</h3>
        <p>{`A title is required so assistive technology can infer equal meaning as a sighted user would.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Use title text that gives context and meaning to the icon." center mdxType="Do">
    <PlusIcon decorative={false} title="Add to cart" mdxType="PlusIcon" /> (Title: "Add to cart")
    <Box position="absolute" right={0} bottom={0} mdxType="Box">
      (Hover over icon for tooltip)
    </Box>
  </Do>
  <Dont title="Avoid" body="Avoid title text that only describes the icon image." center mdxType="Dont">
    <PlusIcon decorative={false} title="Plus" mdxType="PlusIcon" /> (Title: "Plus")
    <Box position="absolute" right={0} bottom={0} mdxType="Box">
      (Hover over icon for tooltip)
    </Box>
  </Dont>
        </DoDont>
        <h3>{`Pair icons with text`}</h3>
        <p>{`Remember that different cultures may interpret the same image in different ways so despite our best intentions it is helpful to provide adjoining text.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Pair icons with text." center mdxType="Do">
    <PlusIcon decorative={true} mdxType="PlusIcon" />
    <Box marginLeft="space20" mdxType="Box">Add to cart</Box>
  </Do>
  <Dont title="Avoid" body="Avoid having standalone icons." center mdxType="Dont">
    <PlusIcon decorative={false} title="Plus" mdxType="PlusIcon" />
  </Dont>
        </DoDont>
        <h3>{`Icons shouldn't be interactive`}</h3>
        <p>{`Icons should not have interactions such as click or hover behavior. Instead they should be wrapped with an
interactive element such as `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button`}</a>{` and `}<a parentName="p" {...{
            "href": "/components/anchor"
          }}>{`Anchor`}</a>{`.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Wrap icons in an interactive component." center mdxType="Do">
    <Button variant="primary" mdxType="Button">
      <PlusIcon decorative={true} mdxType="PlusIcon" />
      Add to cart
    </Button>
  </Do>
  <Dont title="Avoid" body="Avoid making icons interactive without an interactive metaphor." center mdxType="Dont">
    <div onClick={() => {
              alert(`This isn't a great user experience.`);
            }}>
      <PlusIcon decorative={false} title="Add to cart" mdxType="PlusIcon" />
    </div>
  </Dont>
        </DoDont>
        <h2>{`Examples`}</h2>
        <h4>{`Adapting the icon's color`}</h4>
        <p>{`We can change the icon color directly using `}<a parentName="p" {...{
            "href": "/tokens/#text-colors"
          }}>{`text color tokens`}</a>{`.`}</p>
        <LivePreview scope={{
          LoadingIcon
        }} language="jsx" mdxType="LivePreview">
  {`<LoadingIcon decorative={true} color="colorTextError" />`}
        </LivePreview>
        <p>{`Alternatively, icons can inherit the current text color.`}</p>
        <LivePreview scope={{
          Box,
          Text,
          LoadingIcon
        }} language="jsx" mdxType="LivePreview">
  {`<Text as="p" color="colorTextInverse">
  <Box as="span" display="flex" alignItems="center" backgroundColor="colorBackgroundBodyInverse" padding="space30" borderRadius="borderRadius20">
    <LoadingIcon decorative={true}  />
    <Box marginLeft="space20">Now loading</Box>
  </Box>
</Text>`}
        </LivePreview>
        <h4>{`Resizing icons`}</h4>
        <p>{`Icons accept one of our `}<inlineCode parentName="p">{`sizeIcon`}</inlineCode>{` `}<a parentName="p" {...{
            "href": "/tokens/#sizings"
          }}>{`tokens`}</a>{`.`}</p>
        <LivePreview scope={{
          Box,
          Text,
          PlusIcon
        }} language="jsx" mdxType="LivePreview">
  {`<Text as="p">
  <Box><PlusIcon decorative={false} title="Add to cart" size="sizeIcon10" /></Box>
  <Box><PlusIcon decorative={false} title="Add to cart" size="sizeIcon20" /></Box>
  <Box><PlusIcon decorative={false} title="Add to cart" size="sizeIcon30" /></Box>
  <Box><PlusIcon decorative={false} title="Add to cart" size="sizeIcon40" /></Box>
</Text>`}
        </LivePreview>
        <h4>{`Making an icon decorative or informative`}</h4>
        <p>{`If an icon is decorative, no title is necessary. However, if an icon isn't decorative,
you must provide a title property.`}</p>
        <LivePreview scope={{
          Text,
          PlusIcon
        }} language="jsx" mdxType="LivePreview">
  {`<Text as="p">
  <PlusIcon decorative={false} title="Add to cart" />
  <PlusIcon decorative={true} />
</Text>`}
        </LivePreview>
        <h2>{`Usage Guide`}</h2>
        <h3>{`Installation`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/icons
`}</code></pre>
        <h3>{`Usage`}</h3>
        <p>{`Icons have to be imported individually. This import style was decided for `}<a parentName="p" {...{
            "href": "/icons/usage-guidelines#why-react-components"
          }}>{`important
performance benefits`}</a>{`. As our icon set grows to contain over 100 icons, we don't
want projects that use only a few icons to pay the price of a large bundle.`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {PlusIcon} from '@twilio-paste/icons/esm/PlusIcon';

<Button variant="secondary" size="small" onClick={() => {}}>
  <PlusIcon decorative={true} />
  Add to cart
</Button>;
`}</code></pre>
        <h3>{`Props`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`as?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The HTML tag to replace the default `}<inlineCode parentName="td">{`<button>`}</inlineCode>{` tag.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`span`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`title?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`The accessibility text that is read when screenreaders get to this component.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`display?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`ResponsiveValue<CSS.DisplayProperty>`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`block`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`decorative`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Whether or not the SVG is just visual flair or adds meaning to the page. Specifically for screenreaders to know whether to read out the title or not.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`size?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`IconSize`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sizeIcon10`}</inlineCode>{` `}<inlineCode parentName="td">{`sizeIcon20`}</inlineCode>{` `}<inlineCode parentName="td">{`sizeIcon30`}</inlineCode>{` `}<inlineCode parentName="td">{`sizeIcon40`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`sizeIcon20`}</inlineCode></td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`color?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`TextColor`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Sets the icon color to any of our text color tokens or `}<inlineCode parentName="td">{`currentColor`}</inlineCode>{`, which inherits text color from the parent element.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`currentColor`}</inlineCode></td>
            </tr>
          </tbody>
        </table>
        <hr></hr>
        <h2>{`Icon Format`}</h2>
        <p>{`Paste icons are distributed as both SVG files and React components.`}</p>
        <h3>{`Why SVG?`}</h3>
        <p>{`PNGs and JPGs are created using thousands of tiny pixels. SVGs are made out of geometric
primitives such as points, lines, and basic shapes. Because of this attribute, SVGs can
be resized without any loss of quality to the image. We can also write code to change SVG
attributes in realtime. These characteristics are ideal for icons since we usually want to
customize their size or color. To do this with PNGs or JPGs we would need to create a
separate file for each variation.`}</p>
        <h3>{`Why React components?`}</h3>
        <p>{`If you're using any Paste component, you're familiar with how to install and use a React
component. Our icon components have accessibility baked in. They also use our tokens for
sizing and coloring. With icon components, you can import only the icons your project needs.`}</p>
        <p>{`Curious why we chose to make React component icons? Check our `}<a parentName="p" {...{
            "href": "https://github.com/twilio-labs/svg-to-react/blob/master/RATIONALE.md"
          }}>{`write-up document`}</a>{`.`}</p>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      